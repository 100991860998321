<template>
<section class="container">
  <section class="biographical">
    <div class="biographical__wrap inner">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <div class="common__biographi">
          <div class="biographical__top">
            <h3>基本信息</h3>
          </div>
          <div class="basia">
            <el-form-item class="basia__item" prop="username">
              <div class="common__name"><i>*</i>姓名</div>
              <el-input v-model="ruleForm.username"></el-input>
            </el-form-item>
            <el-form-item class="basia__item" prop="sex">
              <div class="common__name"><i>*</i>性别</div>
              <!-- <el-input v-model="ruleForm.sex"></el-input> -->
              <el-select v-model="ruleForm.sex" placeholder="">
                <el-option v-for="item in sexSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="basia__item" prop="age">
              <div class="common__name"><i>*</i>年龄</div>
              <el-input v-model="ruleForm.age"></el-input>
            </el-form-item>
            <el-form-item class="basia__item" prop="birthday">
              <div class="common__name"><i>*</i>出生年月</div>
              <el-date-picker
                v-model="ruleForm.birthday"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="">
              </el-date-picker>
            </el-form-item>
            <el-form-item class="basia__item" prop="political">
              <div class="common__name"><i>*</i>政治面貌</div>
              <!-- <el-input v-model="ruleForm.political"></el-input> -->
              <el-select v-model="ruleForm.political" placeholder="">
                <el-option v-for="item in political"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="basia__item" prop="idcard">
              <div class="common__name"><i>*</i>身份证号</div>
              <el-input v-model="ruleForm.idcard"></el-input>
            </el-form-item>
            <el-form-item class="basia__item" prop="telphone">
              <div class="common__name"><i>*</i>联系电话</div>
              <el-input v-model="ruleForm.telphone"></el-input>
            </el-form-item>
            <el-form-item class="basia__item" prop="height">
              <div class="common__name"><i>*</i>身高</div>
              <el-input v-model="ruleForm.height"></el-input>
              <i class="units">cm</i>
            </el-form-item>
            <el-form-item class="basia__item" prop="weight">
              <div class="common__name"><i>*</i>体重</div>
              <el-input v-model="ruleForm.weight"></el-input>
              <i class="units">kg</i>
            </el-form-item>
            <el-form-item class="basia__item" prop="address">
              <div class="common__name"><i>*</i>家庭地址</div>
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>
            <el-form-item class="basia__item" prop="channel">
              <div class="common__name"><i>*</i>招聘信息获知渠道</div>
              <!-- <el-input v-model="ruleForm.political"></el-input> -->
              <el-select v-model="ruleForm.channel" placeholder="">
                <el-option v-for="item in channelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="basia__item" prop="majorcode">
              <div class="common__name"><i>*</i>专业代码</div>
              <el-select v-model="ruleForm.majorcode" filterable placeholder="">
                <el-option v-for="item in majorcodeList"
                  :key="item.code"
                  :label="item.code  + item.name"
                  :value="item.code  + item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="common__biographi">
          <div class="biographical__top">
            <h3>职称/技能证书及等级</h3>
            <div class="biographi__add" @click="handleAdd">添加+</div>
          </div>
          <div class="resumes__wr">
            <div class="resumes" v-for="(item,index) in ruleForm.certificate" :key="index">
              <div class="common__closes iconfont" @click="handleDelete(index)">&#xe60f;</div>
              <h4 class="resume__title">证书{{index+1}}</h4>
              <div class="resumes__row" >
                <el-form-item class="basia__item" :prop="`certificate[${index}].name`" :rules="[{ required: true, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>职称/技能证书</div>
                  <el-input v-model="item.name"></el-input>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`certificate[${index}].level`" :rules="[{ required: true, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>等级</div>
                  <el-input v-model="item.level"></el-input>
                </el-form-item>
                <div class="uploads">
                  <el-upload
                    :action="uploadUrl"
                    accept=".pdf"
                    multiple
                    :file-list="cerList[index]"
                    :on-change="handleChangeCer.bind(null,{'index':index,'addfill': 'certificate'})"
                    :before-remove="handleBeforeRemoveCer.bind(null,{'index':index,'addfill': 'certificate'})"
                    :before-upload="beforeUpload.bind(null,{'index':index, 'addfill': 'certificate'})"
                  >
                    <el-button class="upload__btns" size="small" type="primary">上传附件</el-button>
                  </el-upload>
                  <p>请上传PDF文件，大小不超10M，且需与自己填写的证书一致!</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="common__biographi">
          <div class="biographical__top">
            <h3>教育经历</h3>
            <div class="biographi__add" @click="educationAdd">添加+</div>
          </div>
          <div class="resumes__wr">
            <div class="resumes" v-for="(item,index) in ruleForm.education" :key="index">
              <div class="common__closes iconfont" @click="educationDelete(index)">&#xe60f;</div>
              <h4 class="resume__title">教育经历{{index + 1}}</h4>
              <div class="resumes__row" >
                <el-form-item class="basia__item" :prop="`education[${index}].enrollmentdate`" :rules="[{ required: false, message: '请选择入学日期', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i></i>入学日期</div>
                  <el-date-picker
                    v-model="item.enrollmentdate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`education[${index}].graduationdate`" :rules="[{ required: false, message: '请选择毕业日期', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i></i>毕业日期</div>
                  <el-date-picker
                    v-model="item.graduationdate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`education[${index}].school`" :rules="[{ required: true, message: '请输入毕业院校', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>毕业院校</div>
                  <el-input v-model="item.school"></el-input>
                </el-form-item>
 
                <el-form-item class="basia__item" :prop="`education[${index}].schooltype`" :rules="[{ required: true, message: '请输入院校类型', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>院校类型</div>
                  <el-select v-model="item.schooltype" placeholder="">
                    <el-option v-for="item in schooltypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`education[${index}].major`" :rules="[{ required: true, message: '请输入专业', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>专业</div>
                  <el-input v-model="item.major"></el-input>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`education[${index}].fulltime`" :rules="[{ required: true, message: '请输入是否全日制', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>是否全日制</div>
                  <!-- <el-input v-model="item.fulltime"></el-input> -->
                  <el-select v-model="item.fulltime" placeholder="">
                    <el-option label="是" value="是"></el-option>
                    <el-option label="否" value="否"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`education[${index}].level`" :rules="[{ required: true, message: '请选择学历层次', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>学历层次</div>
                  <el-select v-model="item.level" placeholder="">
                    <el-option v-for="item in educationLeval"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="common__biographi">
          <div class="biographical__top">
            <h3>工作履历或实习经历</h3>
            <div class="biographi__add" @click="experienceAdd">添加+</div>
          </div>
          <div class="resumes__wr">
            <div class="resumes" v-for="(item,index) in ruleForm.experience" :key="index">
              <div class="common__closes iconfont" @click="experienceDelete(index)">&#xe60f;</div>
              <h4 class="resume__title">工作履历或实习经历{{index+1}}</h4>
              <div class="resumes__row" >
                <el-form-item class="basia__item" :prop="`experience[${index}].startdate`" :rules="[{ required: false, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i></i>开始日期</div>
                  <el-date-picker
                    v-model="item.startdate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`experience[${index}].enddate`" :rules="[{ required: false, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i></i>结束日期</div>
                  <el-date-picker
                    v-model="item.enddate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`experience[${index}].place`" :rules="[{ required: true, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>工作单位</div>
                  <el-input v-model="item.place"></el-input>
                </el-form-item>
                <el-form-item class="basia__item basia__item--textarea" :prop="`experience[${index}].description`" :rules="[{ required: true, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>工作或实习内容简述</div>
                  <el-input type="textarea" v-model="item.description"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <div class="common__biographi">
          <div class="biographical__top">
            <h3>项目经历</h3>
            <div class="biographi__add" @click="projectAdd">添加+</div>
          </div>
          <div class="resumes__wr">
            <div class="resumes" v-for="(item,index) in ruleForm.project" :key="index">
              <div class="common__closes iconfont" @click="projectDelete(index)">&#xe60f;</div>
              <h4 class="resume__title">项目经历{{index+1}}</h4>
              <div class="resumes__row" >
                <el-form-item class="basia__item" :prop="`project[${index}].startdate`" :rules="[{ required: false, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i></i>开始日期</div>
                  <el-date-picker
                    v-model="item.startdate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`project[${index}].enddate`" :rules="[{ required: false, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i></i>结束日期</div>
                  <el-date-picker
                    v-model="item.enddate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`project[${index}].name`" :rules="[{ required: true, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>项目名称</div>
                  <el-input v-model="item.name"></el-input>
                </el-form-item>
                <el-form-item class="basia__item basia__item--textarea" :prop="`project[${index}].description`" :rules="[{ required: true, message: '无', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>项目描述</div>
                  <el-input type="textarea" v-model="item.description"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="common__biographi">
          <div class="biographical__top">
            <h3>获奖经历</h3>
            <div class="biographi__add" @click="awardsAdd">添加+</div>
          </div>
          <div class="resumes__wr">
            <div class="resumes" v-for="(item,index) in ruleForm.awards" :key="index">
              <div class="common__closes iconfont" @click="awardsDelete(index)">&#xe60f;</div>
              <h4 class="resume__title">获奖经历{{index + 1}}</h4>
              <div class="resumes__row" >
                <el-form-item class="basia__item" :prop="`awards[${index}].date`" :rules="[{ required: false, message: '请选择获奖时间', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i></i>获奖时间</div>
                  <el-date-picker
                    v-model="item.date"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="basia__item" :prop="`awards[${index}].name`" :rules="[{ required: true, message: '请输入获奖名称及等级', trigger: ['blur', 'change'] }]">
                  <div class="common__name"><i>*</i>获奖名称及等级</div>
                  <el-input v-model="item.name"></el-input>
                </el-form-item>
                <div class="uploads">
                    <el-upload
                      :action="uploadUrl"
                      accept=".pdf"
                      multiple
                      :file-list="awardsList[index]"
                      :on-change="handleChangeAwards.bind(null,{'index':index,'addfill': 'awards'})"
                      :before-remove="handleBeforeRemoveCer.bind(null,{'index':index,'addfill': 'awards'})"
                      :before-upload="beforeUpload.bind(null,{'index':index, 'addfill': 'awards'})"
                    >
                      <el-button class="upload__btns" size="small" type="primary">上传附件</el-button>
                    </el-upload>
                    <p>请上传PDF文件，大小不超10M，且需与自己填写的获奖经历一致!</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="common__biographi">
          <div class="biographical__top">
            <h3>上传附件资料</h3>
          </div>
          <div class="studen__wrs">
            <div class="Studen__status">
              <el-upload
                :action="uploadUrl"
                accept=".pdf"
                multiple
                :file-list="fileList"
                :on-change="handleChange"
                :before-remove="handleBeforeRemove"
                :before-upload="beforeUpload.bind(null,{'index':0, 'addfill': 'student'})"
              >
                <div class="studens__fix">
                  <img src="@/assets/images/student.png" alt=""/>
                  <p>学籍验证报告</p>
                </div>
              </el-upload>
              
            </div>
            <div class="Studen__status">
              <el-upload
                :action="uploadUrl"
                accept=".pdf"
                multiple
                :file-list="fileList1"
                :on-change="handleChange1"
                :before-remove="handleBeforeRemove1"
                :before-upload="beforeUpload.bind(null,{'index':0, 'addfill': 'student1'})"
              >
                <div class="studens__fix">
                  <img src="@/assets/images/student.png" alt=""/>
                  <p>简历文件</p>
                </div>
              </el-upload>
              
            </div>
            <div class="notes">请上传PDF文件，大小不超10M，且学籍验证报告需与所填教育经历一致!</div>
          </div>
          
        </div>
        <el-form-item class="bio__center">
            <el-button class="biograge__sub" @click="submitForm('ruleForm')">提交</el-button>
          </el-form-item>
      </el-form>
    </div>
  </section>
  <Footer/>
</section>
</template>

<script>
import Footer from '../components/Footer';
export default {
  data() {
    var checkIdCard = (rule, value, callback) => {
      const regIdCard = /^[1-9]\d{5}(18|19|20)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|[Xx])$/;
      if (regIdCard.test(value) === false) {
        callback(new Error('请输入正确的身份证号码'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        "id":0,
        "username":"",
        "sex":"",
        "age":'',
        "birthday":"",
        "political":"",
        "idcard":"",
        "telphone":"",
        "height":"",
        "weight":"",
        "address": "",
        "channel": "",
        "majorcode": "",
        "certificate":[
          {
          "name":"",
          "level":"",
          "fileid":[]
          }
        ],
        "education":[
          {
          "enrollmentdate":"",
          "graduationdate":"",
          "school":"",
          "schooltype":"",
          "major":"",
          "fulltime":"",
          "level":""
          },
        ],
        "experience":[
          {
          "startdate":"",
          "enddate":"",
          "place":"",
          "description":""
          }
        ],
        "project":[
          {
          "startdate":"",
          "enddate":"",
          "name":"",
          "description":""
          }
        ],
        "awards":[
          {
          "date":"",
          "name":"",
          "fileid":[]
          },
        ],
        "fileid":[],
        "resumeid": []
      },
      cerList: [],
      awardsList: [],
      fileList: [],
      fileList1: [],
      majorcodeList: [],
      rules: {
        username: [
          { required: true, message: '请输入姓名', trigger:['blur', 'change'] }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: ['blur', 'change'] }
        ],
        age: [
          { required: true, message: '请输入年龄', trigger: ['blur', 'change'] }
        ],
        birthday: [
          { required: true, message: '请选择出生年月', trigger: ['blur', 'change'] }
        ],
        political: [
          { required: true, message: '请输入政治面貌', trigger: ['blur', 'change'] }
        ],
        idcard: [
          { required: true, message: '请输入身份证号', trigger: ['blur', 'change'] },
          { validator: checkIdCard, trigger: 'blur' }
        ],
        telphone: [
          { required: true, message: '请输入电话', trigger: ['blur', 'change'] },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        height: [
          { required: true, message: '请输入身高', trigger: ['blur', 'change'] }
        ],
        weight: [
          { required: true, message: '请输入体重', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '请输入家庭地址', trigger: ['blur', 'change'] }
        ],
        channel: [
          { required: true, message: '请选择招聘信息获知渠道', trigger: ['blur', 'change'] }
        ],
        majorcode: [
          { required: true, message: '请选择专业代码', trigger: ['blur', 'change'] }
        ]
      },
      sexSelect: [{
        value: '男',
        label: '男'
      }, {
        value: '女',
        label: '女'
      }],
      political: [{
        value: '中共党员',
        label: '中共党员'
      },{
        value: '中共预备党员',
        label: '中共预备党员'
      },{
        value: '共青团员',
        label: '共青团员'
      },{
        value: '民革党员',
        label: '民革党员'
      },{
        value: '民盟盟员',
        label: '民盟盟员'
      },{
        value: '民建会员',
        label: '民建会员'
      },{
        value: '民进会员',
        label: '民进会员'
      },{
        value: '农工党党员',
        label: '农工党党员'
      },{
        value: '致公党党员',
        label: '致公党党员'
      },{
        value: '九三学社社员',
        label: '九三学社社员'
      },{
        value: '台盟盟员',
        label: '台盟盟员'
      },{
        value: '无党派人士',
        label: '无党派人士'
      },{
        value: '群众',
        label: '群众'
      }],
      channelList: [{
        value: '公司官网',
        label: '公司官网'
      },{
        value: '学校就业网站',
        label: '学校就业网站'
      },{
        value: '朋友推荐',
        label: '朋友推荐'
      },{
        value: '其他招聘网站',
        label: '其他招聘网站'
      },{
        value: '其他方式',
        label: '其他方式'
      }],
      schooltypeList: [{
        value: '985',
        label: '985'
      },{
        value: '211',
        label: '211'
      },{
        value: '双一流',
        label: '双一流'
      },{
        value: '普通高校',
        label: '普通高校'
      },{
        value: '国外高校',
        label: '国外高校'
      }],
      educationLeval: [{
        value: '小学',
        label: '小学'
      },{
        value: '初中',
        label: '初中'
      },{
        value: '高中（职高，高技、中专）',
        label: '高中（职高，高技、中专）'
      },{
        value: '大专',
        label: '大专'
      },{
        value: '本科',
        label: '本科'
      },{
        value: '硕士研究生',
        label: '硕士研究生'
      },{
        value: '博士研究生',
        label: '博士研究生'
      }],
      uploadUrl: 'https://gwadmin.anjilog.com/api/webUpload',
    }
  },
  components:{
    Footer
  },
  methods: {
    async getMajorcode(){
      const {data: res} = await this.$http.get("majorcode");
      if(res.status == 1){
        this.majorcodeList = res.data;
      }
    },
    async beforeUpload(index,file) {
      const customField = 'customValue';
      const formData = new FormData();
      formData.append('customField', customField);
      formData.append('file', file);
      const isPDF = file.type === 'application/pdf';
      const isLt10M = file.size / 1024 / 1024 < 10;
 
      if (!isPDF) {
        this.$message.error('只能上传PDF文件!');
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }else{
        const {data: res} = await this.$http.post('webUpload', formData) ;
        console.log(res);
        if(res.status == 1){
            //this.saveForm()
          if(index.addfill == 'student'){
            this.ruleForm.fileid.push(res.data.fileid)
          }else if(index.addfill == 'student1'){
            this.ruleForm.resumeid.push(res.data.fileid)
          }else{
            this.ruleForm[index.addfill][index.index].fileid.push(res.data.fileid)
          }
          this.$message({
              message: res.info,
              type: 'success'
          });
          // console.log( this.ruleForm.certificate)
        }else{
          this.$message.error('上传失败');
        }
        return false;
      }
      return isPDF && isLt2M;
    },
    handleChangeCer(name, file,fileList){
      this.cerList[name.index] = fileList
    },
    handleChangeAwards(name, file,fileList){
      this.awardsList[name.index] = fileList
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      console.log(this.ruleForm)
    },
    handleChange1(file, fileList) {
      this.fileList1 = fileList;
      
    },
    handleAdd() {
      this.ruleForm.certificate.push({
        "name":"",
        "level":"",
        "fileid":[]
        }
      )
    },
    handleDelete(index){
      this.ruleForm.certificate.splice(index, 1);
    },
    educationAdd() {
      this.ruleForm.education.push({
        "enrollmentdate":"",
          "graduationdate":"",
          "school":"",
          "schooltype":"",
          "major":"",
          "fulltime":"",
          "level":" "
        }
      )
    },
    educationDelete(index){
      this.ruleForm.education.splice(index, 1);
    },
    experienceAdd() {
      this.ruleForm.experience.push({
        "startdate":"",
        "enddate":"",
        "place":"",
        "description":""
        }
      )
    },
    experienceDelete(index) {
      this.ruleForm.experience.splice(index, 1);
    },
    projectAdd() {
      console.log( this.ruleForm.project)
      this.ruleForm.project.push({
        "startdate":"",
        "enddate":"",
        "name":"",
        "description":""
      })
    },
    projectDelete(index){
      this.ruleForm.project.splice(index, 1);
    },
    awardsAdd() {
      this.ruleForm.awards.push({
          "date":"",
          "name":"",
          "fileid":[]
        }
      )
    },
    awardsDelete(index) {
      this.ruleForm.awards.splice(index, 1);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        //this.ruleForm.resumeid = []
          if (valid) {
              const {data: res} = await this.$http.post("resumeEdit", this.ruleForm);
              if(res.status == 1){
                this.$message({
                  message: res.info,
                  type: 'success'
                });
                this.$router.push('/personal')
              }
              else{
                this.$message.error(res.info);
              }
          } else {
              this.$message.error(res.info);
              return false;
          }
      });
    },
    async getEdit(){
      let id = this.$route.params.id;
      const {data: res} = await this.$http.get(`resumeInfo?id=${id}`);
      if(res.status == 1){
        let resData = JSON.parse(JSON.stringify(res.data))
        delete resData.fileidinfo
        delete resData.resumeidinfo
        
        resData.awards.forEach((ele)=>{
          delete ele.fileidinfo
        })
        resData.certificate.forEach((ele)=>{
          delete ele.fileidinfo
        })
        this.ruleForm = resData
        this.fileList = res.data.fileidinfo
        this.fileList1 = res.data.resumeidinfo
        res.data.certificate.forEach(element => {
          this.cerList.push(element.fileidinfo)
        });
        res.data.awards.forEach(element => {
          this.awardsList.push(element.fileidinfo)
        });
       // this.cerList = res.data.certificate

      }else{
          this.$message.error('数据请求失败');
      }
    },
    handleBeforeRemoveCer(name, file,fileList){
      this.ruleForm[name.addfill][name.index].fileid = this.ruleForm[name.addfill][name.index].fileid.slice(0, this.fileList.findIndex(f => f.name == file.name)) ;
      if(name.addfill == 'certificate'){
        this.cerList[name.index] = this.cerList[name.index].filter(f => f.name !== file.name)
      }else if(name.addfill == 'awards'){
        this.awardsList[name.index] = this.awardsList[name.index].filter(f => f.name !== file.name)
      }
    },
    handleBeforeRemove(file) {
      this.ruleForm.fileid = this.ruleForm.fileid.slice(0, this.fileList.findIndex(f => f.name == file.name)) ;
      this.fileList = this.fileList.filter(f => f.name !== file.name)
      // this.ruleForm.fileid = this.ruleForm.fileid.slice(0, this.fileList.findIndex(f => f.name == file.name)) ;
      // this.fileList = this.fileList.filter(f => f.name !== file.name)
    },
    handleBeforeRemove1(file) {
      this.ruleForm.resumeid = this.ruleForm.resumeid.slice(0, this.fileList1.findIndex(f => f.name == file.name)) ;
      this.fileList1 = this.fileList1.filter(f => f.name !== file.name)
    },
    // saveForm() {
    //   sessionStorage.setItem('ruleForm', JSON.stringify(this.ruleForm));
    //   sessionStorage.setItem('fileList', JSON.stringify(this.fileList));
    //   sessionStorage.setItem('fileList1', JSON.stringify(this.fileList1));
    //   sessionStorage.setItem('certificate', JSON.stringify(this.cerList));
    //   sessionStorage.setItem('awards', JSON.stringify(this.awardsList));
    // }
  },
  // watch: {
  //   ruleForm: {
  //     handler: function (newValue, oldValue) {
  //       this.saveForm()
  //     },
  //     deep: true, 
  //   }
  // },
  // created() {
  //   const savedFormData = sessionStorage.getItem('ruleForm');
  //   const fileListData = sessionStorage.getItem('fileList');
  //   const fileList1Data = sessionStorage.getItem('fileList1');
  //   const cerListData = sessionStorage.getItem('certificate');
  //   const awardsData = sessionStorage.getItem('awards');
    
  //   if (savedFormData) {
  //     this.ruleForm = JSON.parse(savedFormData);
  //     this.fileList = JSON.parse(fileListData);
  //     this.fileList1 = JSON.parse(fileList1Data);
  //     this.cerList = JSON.parse(cerListData);
  //     this.awardsList = JSON.parse(awardsData);
  //   }
    
  // }
  mounted() {
    this.getEdit();
    this.getMajorcode();
  }
}
</script>

<style>

</style>